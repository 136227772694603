import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utils

// Style
import './index.scss';

export const CitationSection = ({ year, citations }) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<div className={`citations-section`}>
			<h5>{year}</h5>
			<div className='rule-grey'></div>
			<div className='citations-table'>
				<div className='citations-table-header'>
					<p>Author(s)</p>
					<p>Title</p>
					<p>Journal/Conference</p>
				</div>
				<div className='citations-table-content'>
					{citations
						.slice(0, expanded ? citations.length : 5)
						.map(({ Authors, Title, JournalConference, Website }) => {
							return (
								<a className='citation-list-item' href={Website} key={Website}>
									<p className='citation-mobile-header'>Author(s)</p>
									<p>{Authors}</p>
									<p className='citation-mobile-header'>Title</p>
									<p>{Title}</p>
									<p className='citation-mobile-header'>
										Journal/
										<br />
										Conference
									</p>
									<p>{JournalConference}</p>
								</a>
							);
						})}
					{!expanded && (
						<button
							className='see-more-button text-arrow-link'
							onClick={() => setExpanded(true)}
						>
							See more<span>{`❯`}</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

CitationSection.propTypes = {
	year: PropTypes.string.isRequired,
	citations: PropTypes.arrayOf(
		PropTypes.shape({
			Authors: PropTypes.string.isRequired,
			Title: PropTypes.string.isRequired,
			JournalConference: PropTypes.string.isRequired,
			Website: PropTypes.string.isRequired,
		})
	).isRequired,
};
